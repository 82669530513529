<template>
  <div
    :style="{
      height: pageHeight,
      width: pageWidth,
      // backgroundColor: backColor
    }"
  >
    <div class="persionalInfo">
      <div class="headPhoto">
        <img
          class="avatar"
          :src="
            JSON.stringify(userInfo) == '{}' || userInfo.avatar == ''
              ? $Host + '/images/defaultHeadPhoto.png'
              : userInfo.avatar
          "
          alt=""
        />
      </div>
      <div class="info">
        <van-icon
          name="arrow"
          @click="showChangePhotoDialog"
          size="20px"
          class="actionLink"
        />

        <div>
          {{
            JSON.stringify(userInfo) == "{}" || userInfo.name == ""
              ? $t("common.profile.nameIsNull")
              : userInfo.name
          }}
        </div>
        <p></p>
        <div style="font-size: 12px; width: 100%; height: 20px">
          ID:{{ JSON.stringify(userInfo) == "{}" ? "" : userInfo.id }}
          <van-icon
            name="description-o"
            @click="copyId(userInfo.id)"
            size="15px"
            id="userid"
            style="vertical-align: middle; margin-left: 10px"
            color="skyblue"
          />
        </div>
      </div>
    </div>

    <div class="myAction">
      <div class="favorite" @click="toUserActionPage('favorite')">
        <!-- <div class="favorite" @click="toUserActionPage($t('common.profile.favorite'))"> -->
        <van-icon name="like" size="20px" style="vertical-align: middle" />
        {{ $t("common.profile.favorite") }}
        <van-icon
          name="arrow"
          size="20px"
          class="actionLink"
          style="vertical-align: middle"
        />
      </div>
      <div class="history" @click="toUserActionPage('history')">
        <van-icon name="eye" size="20px" style="vertical-align: middle" />
        {{ $t("common.profile.history") }}
        <van-icon
          name="arrow"
          size="20px"
          class="actionLink"
          style="vertical-align: middle"
        />
      </div>
      <div class="languages" @click="showLanguage = true">
        <van-icon
          name="comment-circle"
          size="20px"
          style="vertical-align: middle"
        />
        {{ $t("common.profile.languages") }}
        <van-icon
          name="arrow"
          size="20px"
          class="actionLink"
          style="vertical-align: middle"
        />
      </div>
    </div>

    <!-- <div class="operation">
            <van-cell icon="like" title="Favorites" style="background-color: #22242b;color: #ffffff;" :border="false"
                is-link to="/#/" />
            <van-cell icon="eye" title="Browsing History" style="background-color: #22242b;color: #ffffff;"
                :border="false" is-link to="/#/" />
            <van-cell icon="comment-circle" title="Language" style="background-color: #22242b;color: #ffffff;"
                :border="false" is-link to="/#/" />
            <van-button type="" style="margin-top: 10px; outline: none;border: none;" block color="#333333">Log
                out</van-button>
        </div> -->

    <!-- title="Modify persional info" -->
    <van-dialog
      v-model="changePhotoDialog"
      :show-confirm-button="false"
      style="background-color: #162039"
    >
      <van-form>
        <div class="changePhotoBox">
          <van-uploader
            :after-read="afterRead"
            v-model="avatarObj"
            multiple
            :max-count="1"
          />
        </div>
        <div class="usernameAndGenderInfo">
          <van-field
            v-model="userInfo.name"
            :label="$t('common.profile.nickname')"
            style="background-color: #162039"
            placeholder="Enter your name"
          />
          <van-field
            v-model="userInfo.gender"
            :label="$t('common.profile.gender')"
            style="background-color: #162039"
            placeholder="Enter your gender"
          />
        </div>
        <div class="bottomBtn">
          <van-button
            style="
              outline: none;
              border: none;
              color: white;
              background-color: #162039;
            "
            size=""
            type=""
            @click="cancer"
            >{{ $t("common.profile.cancer") }}</van-button
          >
          <van-button
            style="
              outline: none;
              border: none;
              background-color: #162039;
              color: white;
            "
            size=""
            type=""
            @click="submit"
            >{{ $t("common.profile.confirm") }}</van-button
          >
        </div>
      </van-form>
    </van-dialog>

    <van-action-sheet
      style="background-color: #232631; color: white"
      title="Switch language"
      v-model="showLanguage"
    >
      <div class="languageContent">
        <div class="languageItem" @click="changeLanguage('en')">
          English
          <div class="flag">
            <img :src="$Host + imgurl + 'en.jpg'" alt="" />
          </div>
        </div>

        <div class="languageItem" @click="changeLanguage('zh-CN')">
          中文（简体）
          <div class="flag">
            <img :src="$Host + imgurl + 'zh-CN.jpg'" alt="" />
          </div>
        </div>

        <div class="languageItem" @click="changeLanguage('portugal')">
          língua portuguesa
          <div class="flag">
            <img :src="$Host + imgurl + 'portugal.jpg'" alt="" />
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import request from "../request/request";
import { Toast } from "vant";
export default {
  data() {
    return {
      imgurl: request.IMGURL,
      userInfo: {},
      avatarObj: [],
      changePhotoDialog: false,
      pageHeight: "",
      pageWidth: "",
      showLanguage: false,
      backColor: "#15161c",
      languages: [
        { name: "English", value: "en" },
        { name: "中文（简体）", value: "zh-CN" },
        { name: "língua portuguesa", value: "portugal" },
      ],
      flagPath: [
        {
          en: "/src/static/en.jpg",
        },
        {
          zh: "/src/static/zh-CN.jpg",
        },
        {
          portugal: "/src/static/portugal.jpg",
        },
      ],
    };
  },
  methods: {
    copyId(e) {
      if (e == undefined) {
        Toast('No data')
        return
      }
      navigator.clipboard
        .writeText(e)
        .then((clipText) => {
          Toast("successful");
        })
        .catch((err) => {
          Toast("failed"); // 失败回调
        });
    },
    changeLanguage(e) {
      localStorage.setItem("7topgame.com-user-lang", e);
      this.showLanguage = false;
      localStorage.removeItem("7topgame.com-classify");
      localStorage.removeItem("7topgame.com-game-list");
      localStorage.removeItem("7topgame.com-user-collect");
      localStorage.removeItem("7topgame.com-user-history");

      location.reload();
    },
    selectLanguage(item) {
      console.log(item);
      this.showLanguage = false;
      Toast(item.name);
    },
    toUserActionPage(e) {
      if (e == "favorite") {
        if (JSON.stringify(this.userInfo) == "{}") {
          Toast("You haven't logged in yet");
          setTimeout(() => {
            this.$router.push("/login");
          }, 1500);
          return;
        }
      }
      this.$store.commit("userActionchange", e);
      this.$router.push("/user/action");
    },
    showChangePhotoDialog() {
      if (JSON.stringify(this.userInfo) == "{}") {
        Toast("You haven't logged in yet");
        setTimeout(() => {
          this.$router.push("/login");
        }, 1500);
        return;
      }
      this.changePhotoDialog = true;
    },
    cancer() {
      this.changePhotoDialog = false;
    },
    changePage(page) {
      this.$router.push(page);
    },
    afterRead(file) {},

    submit() {
      if (JSON.stringify(this.userInfo) == "{}") {
        Toast("You haven't logged in yet");
        setTimeout(() => {
          this.$router.push("/login");
        }, 1500);
        return;
      }
      if (this.avatarObj.length <= 0) {
        Toast("Please choose photo first");
        return;
      }

      let fileInfo = this.avatarObj[0].file;

      if (fileInfo.size / 1024 > 1024) {
        Toast("The photo cannot be larger than 1M");
        return;
      }

      let fileType = fileInfo.name.substring(
        fileInfo.name.lastIndexOf("."),
        fileInfo.name.length
      );

      if (fileType !== ".jpg" && fileType !== ".png" && fileType !== ".jpeg") {
        Toast("This file type is not supported");
        return;
      }

      let formData = new FormData();
      formData.append("file", fileInfo);
      formData.append("id", this.userInfo.id);
      formData.append("name", this.userInfo.name);
      formData.append("gender", this.userInfo.gender);
      formData.append("requestUrl", request.HOST + request.IMGURL);

      this.$axios
        .post(this.$Host + "/client/tourist/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.code == 10011) {
            this.changePhotoDialog = false;
            localStorage.setItem(
              "7topgame.com-user-info",
              JSON.stringify(res.data.data)
            );
            this.userInfo = res.data.data;
          }
          Toast(res.data.msg);
        })
        .catch((err) => {
          Toast(res.data.msg);
        });
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));
    if (this.userInfo == null) {
      localStorage.setItem("7topgame.com-user-info", JSON.stringify({}));
      this.userInfo = JSON.parse(
        localStorage.getItem("7topgame.com-user-info")
      );

      // setTimeout(() => {
      //   this.$router.push("/login");
      // }, 1000);
    }
    let height = window.innerHeight;
    let width = window.innerWidth;
    this.pageHeight = height - 83 + "px";
    this.pageWidth = width - 2 + "px";
    console.log(this.userInfo);
  },
};
</script>

<style>
.bottomBox {
  display: flex;
}

.bottomBoxItem {
  flex: 1;
  margin-top: 8px;
  text-align: center;
  color: #ffffff;
}

.persionalInfo {
  display: flex;
  margin: 0 auto;
  margin-top: 25px;
  padding-top: 1px;
  width: 88%;
  height: 95px;
  border-radius: 6px;
  background-color: #22242b;
}

.headPhoto {
  margin: 10px 10px 0 20px;
  width: 70px;
  height: 70px;
  border: 1px solid #ccc;
  border-radius: 35px;
  overflow: hidden;
}

.avatar {
  width: 100%;
  height: 100%;
}

.info {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  color: #ffffff;
  /* border: 1px solid #ccc; */
}

.myAction {
  margin: 0 auto;
  margin-top: 20px;
  width: 88%;
  border-radius: 6px;
  /* border: 1px solid #ccc; */
}

.myAction div {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  margin-top: 15px;
  text-align: left;
  text-indent: 0.5rem;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
  background-color: #22242b;
}

.actionLink {
  float: right;
  margin: 15px;
}

.myAction div div {
  font-size: 18px;
  margin-top: 15px;
}

.myAction .asideMenuTopBoxItemSelected {
  flex: 1;
  margin: 0 5px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  background-color: #30323b;
}

.myAction .asideMenuTopBoxItemSelected div {
  font-size: 18px;
  margin-top: 4px;
  background-color: #30323b;
}

.operation {
  margin: 15px auto;
  width: 88%;
  border-radius: 6px;
  background-color: #22242b;
}

.changePhotoBox {
  margin: 10px auto;
  margin-top: 30px;
  width: 85px;
  height: 85px;
}

.usernameAndGenderInfo {
  margin: 20px auto;
  width: 80%;
  height: 80px;
  border-radius: 6px;
  /* border: 1px solid #ccc; */
}

.bottomBtn {
  display: flex;
  margin: 0px auto;
  margin-top: 20px;
  width: 80%;
  height: 40px;
  /* background-color: skyblue; */
}

.bottomBtn button {
  flex: 1;
  font-size: 14px;
}

.languageItem {
  position: relative;
  margin: 0 auto;
  margin-top: 4px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  /* text-indent: 5rem; */
  text-align: center;
  background-color: #2e313b;
}

.flag {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 40px;
  height: 30px;
  /* background-color: aqua; */
  border-radius: 8px;
  overflow: hidden;
}

.flag img {
  width: 100%;
  height: 100%;
}

.usernameAndGenderInfo .van-field__control {
  color: white;
}

.usernameAndGenderInfo .van-field__label {
  color: white;
}
</style>
