var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mainPage",style:({
        height: _vm.pageHeight,
        width: _vm.pageWidth,
      })},[_c('div',{attrs:{"id":"websiteWords"}},[_vm._v(_vm._s(_vm.$t("common.login.bannerWord")))]),_c('div',{staticClass:"loginBox"},[_c('van-field',{staticClass:"loginIpu",attrs:{"type":"digit","maxlength":"20","input-align":"center","placeholder":_vm.$t('common.login.inputTip')},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}}),_c('van-divider',{style:({
            color: '#9834e9',
            borderColor: '#9834e9',
            padding: '0 16px',
            fontSize: '12px',
          })},[_vm._v(" OU ")]),_c('van-button',{staticStyle:{"border-radius":"20px"},attrs:{"type":"info","color":"linear-gradient(to bottom, #9535f2, #b22194)","block":""},on:{"click":_vm.signInByTourist}},[_vm._v(_vm._s(_vm.$t("common.login.signInWithTourist"))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }