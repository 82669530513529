<template>
  <div>
    <div class="gameItemBoxMin" @click="showGameInfoActionSheet">
      <div v-if="game.cid == 1" class="iconTop">
        {{ $t("common.home.hot") }}
      </div>
      <img class="gameImg" :src="game.image" alt="" />
      <div class="gameName">{{ game.gameName }}</div>
    </div>

    <van-action-sheet v-model="showGameInfo" style="background-color: #1c2028">
      <div class="gameItemContent">
        <div class="gameItemInfo">
          <div class="gameItemImg">
            <img class="gameImg" :src="game.image" alt="" />
          </div>
          <div class="gameItem">
            <p></p>
            <div class="gameItemName">
              {{ game.gameName }}
            </div>
            <div class="description">
              {{ $t("common.userAction.start") }}
            </div>
            <div class="played">
              {{ game.openNum }} {{ $t("common.userAction.played") }}
            </div>
          </div>
        </div>
        <div class="gameItemStart">
          <van-button
            icon="play-circle"
            style="font-size: 20px"
            type="info"
            block
            @click="toGame"
            >{{ $t("common.button.play") }}</van-button
          >
        </div>
        <div class="collect">
          <van-icon
            name="like-o"
            v-if="!isCollect"
            @click="collect"
            size="25px"
          />
          <van-icon name="like" v-else color="red" @click="del" size="25px" />
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";

import { Toast } from "vant";
export default {
  props: {
    game: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      show: false,
      showGameInfo: false,
      gameItem: {},
      isCollect: false,
      userInfo: {},
    };
  },
  methods: {
    del() {
      let userCollect = {
        tid: this.userInfo.id,
        gameId: this.game.id,
      };
      this.$axios
        .post(this.$Host + "/client/tourist/collect/del", userCollect)
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 10021) {
            let localUserCollect = JSON.parse(
              localStorage.getItem("7topgame.com-user-collect")
            );
            if (localUserCollect != null) {
              for (let i = 0; i < localUserCollect.length; i++) {
                const element = localUserCollect[i];
                if (userCollect.gameId == element.gameId) {
                  localUserCollect.splice(i, 1);
                  break;
                }
              }
            }
            localStorage.setItem(
              "7topgame.com-user-collect",
              JSON.stringify(localUserCollect)
            );
            this.isCollect = false;
            Toast("cancer collect successful");
            return;
          }
          Toast("cancer collect error");
        })
        .catch((err) => {
          Toast(err);
        });
    },
    collect() {
      console.log(
        this.userInfo == null && JSON.stringify(this.userInfo) == "{}"
      );

      if (this.userInfo == null || Object.keys(this.userInfo).length == 0) {
        Toast("You haven't logged in yet");
        setTimeout(() => {
          this.$router.push("/login");
        }, 1500);
        return;
      }

      let userCollect = {
        tid: this.userInfo.id,
        gameId: this.game.id,
      };
      const analytics = getAnalytics();

      logEvent(analytics, "collect", {
        // content_type: 'link',
        uid: this.userInfo.id,
        gid: this.game.id,
      });

      this.$axios
        .post(this.$Host + "/client/tourist/collect/insert", userCollect)
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 10011) {
            localStorage.setItem(
              "7topgame.com-user-collect",
              JSON.stringify(res.data.data)
            );
            this.isCollect = true;
            Toast("collect successful");
            return;
          }
          Toast("collect error");
        })
        .catch((err) => {
          Toast(err);
        });
    },
    isCollectGame() {
      let collect = JSON.parse(
        localStorage.getItem("7topgame.com-user-collect")
      );
      if (collect == null) {
        return;
      }
      for (let i = 0; i < collect.length; i++) {
        const element = collect[i];
        console.log(element.gameId, this.game.id);
        if (element.gameId == this.game.id) {
          this.isCollect = true;
          break;
        } else {
          this.isCollect = false;
        }
      }
    },

    showGameInfoActionSheet() {
      this.showGameInfo = true;
      this.isCollectGame();
      let arr = localStorage.getItem("7topgame.com-user-history");
      if (arr == null) {
        arr = [];
        arr.push(this.game);
        localStorage.setItem("7topgame.com-user-history", JSON.stringify(arr));
        return;
      } else {
        let oldArr = JSON.parse(arr);
        oldArr.push(this.game);
        let newArr = this.deWeight(oldArr);
        localStorage.setItem(
          "7topgame.com-user-history",
          JSON.stringify(newArr)
        );
      }
    },

    deWeight(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].id == arr[j].id) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },

    toGame() {
      const analytics = getAnalytics();

      logEvent(analytics, "open_game", {
        // content_type: 'link',
        game_name: this.game.gameName,
      });

      this.$axios
        .get(this.$Host + "/client/game/openGame?id=" + this.game.id)
        .then((res) => {
          location.href = this.game.gameUrl;
        })
        .catch((err) => {});
    },
  },
  components: {},
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));
    let mobileDeviceSize = document.querySelector(".gameItemBoxMin");

    let width = window.innerWidth;
    console.log(width);
    
    if (width > 400 && width < 550) {
      mobileDeviceSize.className = "gameItemBoxMax";
    } else if (width > 370 && width < 400) {
      mobileDeviceSize.className = "gameItemBoxNormal";
    } else if (width < 370) {
      mobileDeviceSize.className = "gameItemBoxMin";
    }else if (width > 550) {
      mobileDeviceSize.className = "gameItemBoxPc";
    }
  },
};
</script>

<style>
.gameItemBoxMin {
  position: relative;
  width: 105px;
  /* max-width: 190px; */
  height: 120px;
  border-radius: 10px;
}

.gameItemBoxNormal {
  position: relative;
  width: 110px;
  /* max-width: 190px; */
  height: 130px;
  border-radius: 10px;
}

.gameItemBoxMax {
  position: relative;
  width: 120px;
  /* max-width: 190px; */
  height: 140px;
  border-radius: 10px;
}

.gameItemBoxPc {
  position: relative;
  width: 200px;
  /* max-width: 190px; */
  height: 220px;
  border-radius: 10px;
}

.gameItemBoxPc .gameName{
  font-size: 18px;
  line-height: 50px;
}

.iconTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  border-bottom-right-radius: 10px;
  background-color: orange;
  color: white;
  font-weight: bold;
  z-index: 1;
}

.gameName {
  position: absolute;
  bottom: 0;
  margin-top: 100px;
  width: 100%;
  height: 23%;
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  box-shadow: 10 10px rgba(240, 0, 0, 0.75);
  backdrop-filter: blur(2px);
  /* box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0); */
}

.gameImg {
  position: relative;
  top: 50%;
  left: 50%;
  max-width: 180%;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

.gameItemContent {
  padding: 16px 16px 40px 16px;
  position: relative;
}

.gameItemInfo {
  padding: 10px 0;
  display: flex;
  margin: 20px auto;
  margin-left: 20px;
  width: 94%;
  height: 120px;
  font-size: 16px;
  /* font-weight: bold; */
  color: white;
}

.gameItemImg {
  flex: 0.7;
  border-radius: 10px;
  overflow: hidden;
}

.gameItem {
  flex: 1.3;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.gameItemStart {
  margin: 0px auto;
  width: 94%;
  border-radius: 6px;
  overflow: hidden;
}

.description {
  flex: 1;
  width: 100%;
  height: 30%;
  margin-top: 10px;
  font-size: 14px;
  word-wrap: break-word;
  /* 允许在单词内部换行 */
  white-space: wrap;
  color: rgb(219, 224, 207);
}

.gameItemName {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.played {
  flex: 1;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  /* overflow: hidden; */
  color: rgb(219, 224, 207);
}

.collect {
  position: absolute;
  right: 5%;
  top: 16%;
  /* background-color: red; */
  width: 30px;
  height: 30px;
}
</style>
