<template>
    <div :style="{
        width: pageWidth,
        height: pageHeight,
        backgroundColor: backColor,
    }">
        <Navi></Navi>

        <div class="allClassifyGamesBox">
            <div style="line-height: 35px;">
                <img style="width: 25px;height: 25px; vertical-align: middle;" :src="icon" alt="">
                {{ classification }}
            </div>
        </div>

        <div class="gamesBox">
            <div class="gameItem" v-for="item in gameData">
                <Game :game="item">
                </Game>
            </div>
        </div>

        <!-- <div class="placeorder" style="width: 100%;height: 100px;"></div> -->

        <footer class="bottom">
            <Bottom></Bottom>
        </footer>
    </div>
</template>

<script>
import Bottom from '../components/Bottom.vue';
import Navi from './Navi.vue'
import Game from '../components/Game.vue';
import Classify from '../components/Classify.vue'
export default {
    data() {
        return {
            classification: '',
            icon: '',
            gameData: [],
            pageHeight: "",
            pageWidth: "",
            backColor: "#15161c",
        }
    },
    methods: {
        findGameByPageAndClassify() {
            let cond = {
                begin: 1, size: 100, lang: localStorage.getItem('7topgame.com-user-lang'), classifyName: this.classification
            }
            this.$axios
                .post(this.$Host + '/client/game/findByPage', cond)
                .then(res => {

                    if (res.data.code == 10041) {
                        this.gameData = res.data.data.data
                    }
                })
                .catch(err => {
                    this.$message.error(err)
                })
        },
        getIcon(e) {
            let classes = JSON.parse(localStorage.getItem('7topgame.com-classify'))
            for (let i = 0; i < classes.length; i++) {
                const element = classes[i];
                if (e == element.name) {
                    this.icon = this.$Host + element.icon;
                    break
                }
            }
        },
    },
    components: {
        Bottom,
        Navi,
        Game,
        Classify
    },
    watch: {
        $route: function (to, from) {
            this.classification = to.query.classification
            this.gameData = []
            setTimeout(() => {
                this.findGameByPageAndClassify()
            }, 500);
        },
    },
    mounted() {
        // this.routerParams = this.$route.query.classification;
        this.classification = this.$route.query.classification
        this.getIcon(this.classification)
        this.findGameByPageAndClassify()
        let height = window.innerHeight;
        let width = window.innerWidth;
        this.pageHeight = height - 2 + "px";
        this.pageWidth = width - 2 + "px";
    },
}
</script>

<style>
.allClassifyGamesBox {
    display: flex;
    /* justify-content: center; */
    margin: 0 auto;
    margin-top: 10px;
    width: 96%;
    line-height: 20px;
    /* height: 35px; */
    /* border: 1px solid #ccc; */
}

.allClassifyGamesBox div {
    flex: 1;
    margin: 0 10px;
    margin-bottom: 10px;
    /* line-height: 35px; */
    /* text-indent: 1rem; */
    color: white;
    font-size: 18px;
    text-align: left;
    /* background-color: pink; */
}

.gamesBox {
    margin: 0 auto;
    width: 96%;
    background-color: #15161c;
}

.gameItem {

    border-radius: 10px;
    overflow: hidden;
}
</style>
