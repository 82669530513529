// 葡萄牙语言包

export default {
    common: {
        // 英语登录界面语言
        login: {
            bannerWord: 'Bem-vindo ao login',
            account: 'Email',
            verifyCode: 'Verificar código',
            sendCode: "Enviar código",
            password: "Senha",
            signInWithPassword: 'Entrar com senha',
            signInWithEmail: 'Entrar com e-mail',
            signIn: 'Entrar',
            signInWithTourist: 'Cadastre-se com turista',
            signInWithGoogle: 'Entrar com google',
            inputTip: 'Digite seu id (opcional)'
        },
        // 英语注册界面语言
        register: {
            bannerWord: 'Bem-vindo a se inscrever',
            account: 'Email',
            verifyCode: 'Verificar código',
            sendCode: "Enviar código",
            password: "Senha",
            signInWithPassword: 'Entrar com senha',
            signInWithEmail: 'Entrar com e-mail',
            signUp: 'Entrar',
        },
        // 英语主界面语言
        home: {
            signIn: 'Entrar',
            signUp: 'Inscrição',
            recommend: 'Recomendar',
            more: 'Mais',
            hot: 'Quente',
            new: 'NOVO',
            search: 'Busca',
            result: 'Resultados Da Busca',
            enterGameName: 'Digite o nome do jogo',
            searchLimit: 'Procurar pelo menos três caracteres!',
            history: 'O histórico de navegação',
        },
        // 英语资料界面语言
        profile: {
            favorite: "Favorito",
            history: "O histórico de navegação",
            languages: "Idiomas",
            nickname: 'Apelido',
            gender: 'Gênero',
            confirm: 'Confirmar',
            cancer: 'Câncer',
            nameIsNull: 'Vazio',
        },
        // 英语按钮语言
        bottom: {
            home: 'Casa',
            profile: 'Mina',
        },
        // 用户浏览喜欢语言
        userAction: {
            favorite: "Favorito",
            history: "O histórico de navegação",
            played: ' Pessoa jogou',
            start: 'Clique para começar o jogo!'
        },
        button: {
            play: 'Brincar'
        },

    }
}

