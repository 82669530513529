import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// 改变变量值的方法（异步）
const actions = {

}

// 改变变量值的方法（同步）
const mutations = {
    // 这里不能直接用state，要先传参!!!!
    addTagList(state, val) {
        if (val.name !== 'home') {
            let index = state.tagList.findIndex(item => item.name === val.name)
            if (index === -1) {
                state.tagList.push(val)
            }
        }
    },
    userActionchange(state, val) {
        state.userAction = val
    },
    addHistoryRecords(state, val) {
        state.historyData = val
    },
}

// 存储变量值的方法
const state = {
    isCollapse: false,
    tagList: [
        {
            path: '/home/menu',
            name: 'home',
            label: '首页',
            icon: 's-home',
            url: 'Home/Home'
        }
    ],
    userAction: 'favorite',
    historyData: [],
    favoriteData: []
}

const Store = new Vuex.Store({
    actions,
    mutations,
    state
})

export default Store
