//英文语言包en.js

export default {
    common: {
        // 英语登录界面语言
        login: {
            bannerWord: 'WELCOME TO SIGN IN',
            account: 'Email',
            verifyCode: 'Verify code',
            sendCode: "Send code",
            password: "Password",
            signInWithPassword: 'Sign in with password',
            signInWithEmail: 'Sign in with email',
            signIn: 'Sign in',
            signInWithTourist: 'LOG IN AS A GUEST',
            signInWithGoogle: 'LOG IN VIA GOOGLE',
            inputTip: 'Enter your ID(Required)'
        },
        // 英语注册界面语言
        register: {
            bannerWord: 'Welcome to sign up',
            account: 'Email',
            verifyCode: 'Verify code',
            sendCode: "Send code",
            password: "Password",
            signInWithPassword: 'Sign up with password',
            signInWithEmail: 'Sign up with email',
            signUp: 'Sign up',
        },
        // 英语主界面语言
        home: {
            signIn: 'Sign in',
            signUp: 'Sign up',
            recommend: 'recommend',
            more: 'MORE',
            hot: 'HOT',
            new: 'NEW',
            search: 'Search',
            result: 'Search result',
            enterGameName: 'Enter Game name',
            searchLimit: 'Search for at least three characters!',
            history: 'Browsing history',
        },
        // 英语资料界面语言
        profile: {
            favorite: "Favorite",
            history: "Broshing History",
            languages: "Languages",
            nickname: 'Nickname',
            gender: 'Gender',
            confirm:'Confirm',
            cancer: 'Cancer',
            nameIsNull: 'Empty',
        },
        // 英语按钮语言
        bottom: {
            home: 'Home',
            profile: 'Mine',
        },
        // 用户浏览喜欢语言
        userAction: {
            favorite: 'Favorite',
            history: 'History',
            played: ' People have played',
            start: 'Click to start the game!'
        },
        // 开始按钮
        button: {
            play: 'Play'
        }
    }
}

