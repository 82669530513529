<template>
    <div>
        <div class="bottomBox">
            <div class="bottomBoxItem" @click="changePage('/home/menu', 0)">
                <div>
                    <img class="mainBottomIcon" :src="$Host + '/images/home.png'" alt="">
                </div>
                <div style="font-size: 10px;"> {{ $t('common.bottom.home') }}</div>
            </div>
            <div class="bottomBoxItem" @click="changePage('/profile/info', 1)">
                <div>
                    <img class="mainBottomIcon" :src="$Host + '/images/mine.png'" alt="">
                </div>
                <div style="font-size: 10px;"> {{ $t('common.bottom.profile') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        changePage(page, index) {
            let items = document.querySelectorAll('.bottomBoxItem')
            let icons = document.querySelectorAll('.mainBottomIcon')

            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                if (i == index) {
                    element.className = 'bottomBoxItem selected'
                } else {
                    element.className = 'bottomBoxItem'
                    icons[i].src = this.$Host + '/images/home.png'

                }
            }

            if (index == 0) {
                icons[0].src = this.$Host + '/images/homeSelected.png'
                icons[1].src = this.$Host + '/images/mine.png'
            }else{
                icons[0].src = this.$Host + '/images/home.png'
                icons[1].src = this.$Host + '/images/mineSelected.png'
            }

            this.$router.push(page)
        },
    },
    mounted() {

        let icons = document.querySelectorAll('.mainBottomIcon')

        if (this.$route.path.includes("/profile")) {
            document.querySelectorAll('.bottomBoxItem')[1].className = 'bottomBoxItem selected'
            document.querySelectorAll('.bottomBoxItem')[0].className = 'bottomBoxItem'
            icons[0].src = this.$Host + '/images/home.png'
            icons[1].src = this.$Host + '/images/mineSelected.png'
        }
        if (this.$route.path.includes("/home")) {
            document.querySelectorAll('.bottomBoxItem')[0].className = 'bottomBoxItem selected'
            document.querySelectorAll('.bottomBoxItem')[1].className = 'bottomBoxItem'
            icons[0].src = this.$Host + '/images/homeSelected.png'
            icons[1].src = this.$Host + '/images/mine.png'
        }

    }
}
</script>

<style>
.bottomBox {
    display: flex;
}

.bottomBoxItem {
    flex: 1;
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
    color: white;
}

.selected {
    color: #ab26ad;
}

.mainBottomIcon {
    width: 26px;
    height: 26px;
}
</style>
