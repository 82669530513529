//中文语言包zh.js

export default {
    common: {
        login: {
            bannerWord: '欢迎登陆',
            account: '邮箱',
            verifyCode: '验证码',
            sendCode: "发送",
            password: "密码",
            signInWithPassword: '通过密码登陆',
            signInWithEmail: '通过邮箱登陆',
            signIn: '登录',
            signInWithTourist: '通过游客登陆',
            signInWithGoogle: '通过谷歌登录',
            inputTip: '输入你的id（选填）'
        },
        register: {
            bannerWord: '欢迎注册',
            account: '邮箱',
            verifyCode: '验证码',
            sendCode: "发送",
            password: "密码",
            signInWithPassword: '通过密码注册',
            signInWithEmail: '通过邮箱注册',
            signUp: '注册',
        },
        home: {
            signIn: '登录',
            signUp: '注册',
            recommend: '推荐',
            more: '更多',
            hot: '热门',
            new: '最新',
            search: '搜索',
            result: '搜索结果',
            enterGameName: '输入游戏名',
            searchLimit: '至少搜索三个字符!',
            history: '浏览历史',
        },
        profile: {
            favorite: "我的喜欢",
            history: "浏览历史",
            languages: "语言",
            nickname: '昵称',
            gender: '性别',
            confirm: '确认',
            cancer: '取消',
            nameIsNull:'空名字',
        },
        bottom: {
            home: '主页',
            profile: '我的'
        },
        userAction: {
            favorite: '我的喜欢',
            history: '浏览历史',
            played: ' 人玩过',
            start: '点击开始游戏吧！'
        },
        button: {
            play: '开始'
        }
    }
}