<template>
    <div>
        <div class="game-swiper" v-if="isMobile">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="item in slideshowData">
                    <img class="bg" :src="item.image" @click="showGameInfoActionSheet(item)" alt="">
                </van-swipe-item>
            </van-swipe>
        </div>


    </div>
</template>

<script>
export default {
    data() {
        return {
            slideshowData: [],
            game: {},
            isMobile:true,
        }
    },
    methods: {
        findByStatus() {
            this.$axios.get(this.$Host + '/client/slideshow/findByStatus', {
                headers: {
                    token: this.accessId
                }
            }).then(res => {
                console.log(res.data);
                if (res.data.code === 10041) {
                    this.slideshowData = res.data.data
                    // this.totalPage = res.data.data.count
                    return
                }
            })
        },
        showGameInfoActionSheet(item) {
            this.$axios
                .get(this.$Host + "/client/game/openGame?id=" + item.id)
                .then((res) => {
                    location.href = item.url
                })
                .catch((err) => {
                });

            // location.href ="https://mini80.com/lucky1/game/tank-craft/play"
        },
        toGame() {
            console.log(this.game.url);

        },
    },
    mounted() {
        let width = window.innerWidth;
        if (width > 550) {
            this.isMobile = false
        }
        this.findByStatus()
    }
}
</script>

<style>
.game-swiper {
    margin: 5px auto;
    width: 96%;
    border-radius: 5px;
    overflow: hidden;
}

.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    max-height: 180px;
    text-align: center;
}

.my-swipe .van-swipe-itemPc {
    color: #fff;
    font-size: 20px;
    max-height: 480px;
    text-align: center;
}

.bg {
    width: 100%;
    height: 100%;
}

.content {
    padding: 16px 16px 40px 16px;
}

.gameInfo {
    display: flex;
    margin: 10px auto;
    width: 94%;
    height: 120px;
    /* background-color: aqua; */
}

.gameImg {
    flex: 0.6;
    background-color: black;
}

.info {
    flex: 1.4;
}

.gameStart {
    margin: 0px auto;
    width: 94%;
    border-radius: 6px;
    overflow: hidden;
    /* background-color: pink; */
}
</style>
