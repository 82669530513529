import Home from '../components/Home.vue'
import Profile from '../components/Profile.vue'
// import Slideshow from '../components/Slideshow.vue'
// import Nav from '../views/Nav.vue'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import UserAction from '../views/UserAction.vue'
import AllGameByClassify from '../views/AllGameByClassify.vue'


import VueRouter from 'vue-router'
import Vue from 'vue'

export default new VueRouter({
    mode:'history',
    routes: [
        {
            path: '/',
            component: Main,
            // 重定向
            redirect: '/home/menu',
            children: [
                { path: '/home/menu', component: Home },
                { path: '/profile/info', component: Profile },
            ]

        },
        {
            path: '/login', component: Login
        },
        {
            path: '/register', component: Register
        },
        {
            path: '/user/action', component: UserAction
        },
        {
            path: '/all/games', component: AllGameByClassify
        }

    ]
})

Vue.use(VueRouter)
