<template>
  <div>
    <nav class="navi">
      <div class="logoBox" @click="$router.push('/')">
        <div class="logo">
          <img :src="$Host +  '/images/logo.png'" alt="" />
        </div>
        7topgame
      </div>

      <div class="asideMenu" @click="showPopup">
        <!-- <i class="el-icon-s-unfold" style="color: #ccc"></i> -->
        <van-icon name="bars" color="#b7b8b9" style="font-weight: bold;" />
      </div>
      <!-- <div class="signupBox">
        <van-button
          size="small"
          color="#1b2746"
          style="margin-top: 6px; font-size: 14px"
          type="default"
          >{{ $t("common.home.signIn") }}</van-button
        >
        <van-button
          size="small"
          type="primary"
          style="margin-top: 6px; font-size: 16px; border-radius: 10px"
          >{{ $t("common.home.signUp") }}</van-button
        >
      </div> -->
    </nav>

    <van-popup v-model="show" position="left" style="background-color: #070c18" closeable
      :style="{ width: '50%', height: '100%' }">
      <div class="asideMenuTopBox">
        <div class="logoBoxes">
          <div class="logoIcon">
            <img :src="$Host + '/images/logo.png'" alt="" />
          </div>
          <div class="logoName">7tomgame</div>
        </div>
        <!-- <div class="popular">
                    <div>
                        <van-icon name="thumb-circle-o" />
                    </div>
                    popular
                </div>
                <div class="collect">
                    <div>
                        <van-icon name="like-o" />
                    </div>
                    collect
                </div>
                <div class="recentes">
                    <div>
                        <van-icon name="clock-o" />
                    </div>
                    recentes
                </div> -->
      </div>

      <div class="actionSheet">
        <div class="actionSheetItem" v-for="item in classifyData" v-if="item.name != 'All' && item.name != '全部' && item.name != 'Todo'"
          @click="toAllGames(item.name)">
          <img :src="$Host + item.icon" alt="">
          {{ item.name }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
    let user = localStorage.getItem("7topgame.com-user-info");
    let c = localStorage.getItem("7topgame.com-classify");
    this.classifyData = JSON.parse(c);
  },
  data() {
    return {
      show: false,
      isLogin: false,
      classifyData: [],
    };
  },
  methods: {
    showPopup() {
      this.show = true;
      this.initializeClassify();
    },
    initializeClassify() {
      let lCode = localStorage.getItem("7topgame.com-user-lang");
      this.$axios
        .get(this.$Host + "/common/gameClassify/findByLanguage?l=" + lCode)
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 10041) {
            this.classifyData = res.data.data;
            localStorage.setItem(
              "7topgame.com-classify",
              JSON.stringify(res.data.data)
            );
            // for (let i = 0; i < 5; i++) {
            //     const element = res.data.data[i];
            //     this.classifyData.push(element)
            // }
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
    toAllGames(val) {
      // this.$router.push('/all')

      //   setTimeout(() => {
      this.$router.push({ path: "/all/games", query: { classification: val } });
      this.show = false;
      //   }, 500);
      // location.href ='/#/all/games?classification=' + val
    },
  },

  computed: {
    ...mapState({
      tags: (state) => state.tagList,
    }),
  },
};
</script>

<style>
body {
  padding: 0;
  margin: 0;
}

.navi {
  position: relative;
  width: 100%;
  height: 56px;
}

.asideMenu {
  display: inline-block;
  margin: 16px 0 0 10px;
  width: 7%;
  height: 40%;
  /* background-color: red; */
  /* display: inline-block;
   margin: 10px 0 0 0 ;  */
}

.logoBox {
  position: absolute;
  /* position: relative; */
  top: 0;
  left: 40px;
  width: 150px;
  max-width: 200px;
  height: 100%;
  line-height: 56px;
  text-align: right;
  color: white;
  font-size: 22px;
}

.logo {
  position: absolute;
  width: 42px;
  height: 56px;
}

.logo img {
  margin-top: 6px;
  width: 90%;
}

.asideMenu i {
  font-size: 20px;
  color: #516382;
}

.signupBox {
  display: flex;
  margin: 5px 5px 0 0;
  float: right;
  width: 45%;
  height: 80%;
  border-radius: 5px;
}

.signupBox button {
  flex: 1;
}

.signupBox button:focus {
  outline: none;
  box-shadow: none;
}

.asideMenuTopBox {
  display: flex;
  /* flex-direction: column; */
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 10px;
  width: 85%;
  height: 45px;
  border-radius: 2px;
  border-bottom: 3px solid #913feb;
  background-image: linear-gradient(to top, #462278, #080d1b);
}

.logoBoxes {
  display: flex;
  margin: 0 auto;
  width: 80%;
  margin-top: 2px;
  height: 40px;
  /* background-color: aqua; */
}

.logoName {
  flex: 1.5;
  color: white;
  font-size: 22px;
  text-align: center;
  line-height: 40px;
}

.logoIcon {
  flex: 0.5;
}

.logoIcon img {
  width: 36px;
}

/* .asideMenuTopBox div {
  flex: 1;
  margin: 0 5px;
  text-align: center;
  color: #92a2c0;
  font-size: 12px;
  border-radius: 5px;
  background-color: #22242b;
} */

/* .asideMenuTopBox div div {
  font-size: 18px;
  margin-top: 4px;
} */

.asideMenuTopBox .asideMenuTopBoxItemSelected {
  flex: 1;
  margin: 0 0px;
  text-align: center;
  color: #92a2c0;
  font-size: 12px;
  border-radius: 5px;
  background-color: #30323b;
}

.asideMenuTopBox .asideMenuTopBoxItemSelected div {
  font-size: 18px;
  margin-top: 4px;
  background-color: #30323b;
}

.actionSheet {
  margin: 0 auto;
  width: 86%;
  border-radius: 2px;
}

.actionSheetItem {
  margin: 5px auto;
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #ccc;
  text-indent: 2rem;
  font-size: 13px;
  background-color: #232631;
  border-radius: 7px;
}

.actionSheetItem img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
</style>
