<template>
  <div style="z-index: 888">
    <div class="classifyBox">
      <div
        class="leftArea"
        style="
          /* margin-bottom: 10px; */
          height: 25px;
          /* line-height: 30px; */
          display: flex;
          align-items: left;
          justify-content: left;
        "
      >
        <!-- <van-icon name="fire" /> -->
        <img class="iconItem" :src="$Host + classification.icon" alt="" />
        {{ classification.name }}
      </div>
      <div class="rightArea">
        <button class="moreBtn" @click="toAllGames">
          {{ $t("common.home.more") }}
        </button>
        <!-- <van-button  size="mini" icon="arrow-left" style="background-color: #1d2027; border: none;" />
                <van-button  size="mini" icon="arrow" style="background-color: #1d2027; border: none;" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classification: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  methods: {
    toAllGames() {
      this.$router.push({
        path: "/all/games",
        query: { classification: this.classification.name },
      });
    },
  },
  mounted() {},
};
</script>

<style>
.classifyBox {
  display: flex;
  /* justify-content: center; */
  margin: 8px auto;
  margin-top: 7px;
  width: 96%;
  z-index: 999;
  /* height: 35px; */
  /* border: 1px solid #ccc; */
}

.classifyBox div {
  flex: 1;
  margin: 0 10px;
  /* line-height: 35px; */
  /* text-indent: 1rem; */
  color: white;
  font-size: 14px;
  /* background-color: pink; */
}

.rightArea {
  text-align: right;
}

.leftArea{
  line-height: 28px;
}

.moreBtn {
  width: 40px;
  height: 21px;
  font-size: 9px;
  background-color: #3e1b68;
  color: #7931ca;
  border: none;
  border-radius: 8px;
}

.iconItem {
  float: left;
  margin-right: 20px;
  width: 25px;
  height: 25px;
}
</style>
