var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainBox",style:({
  // height: pageHeight,
  width: _vm.pageWidth,
  minHeight: '700px',
  backgroundColor: _vm.backColor,
  // backgroundImage: isMainHome ? gradient : ''
})},[_c('Navi',{ref:"Navi"}),_c('main',[_c('router-view')],1),_c('footer',{staticClass:"bottom"},[_c('Bottom')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }