<template>
  <div
    :style="{
      width: pageWidth,
      // height: pageHeight,
      // backgroundColor: backColor,
    }"
  >
    <div class="swiper">
      <Slideshow></Slideshow>
    </div>

    <div>
      <div class="classifyNavBox">
        <!-- <div class="classifyItem" @click="scrollToItem('gameClassify' + item.id)" v-for="item in classifyData">
                {{ item.name }}
            </div> -->
        <div
          class="classifyItem"
          v-for="(item, index) in classifyData"
          @click="findGameByPageAndClassify(item, index)"
        >
          <div class="iconPosition">
            <img :src="$Host + item.icon" alt="" /> {{ item.name }}
          </div>
        </div>
      </div>

      <div class="searchBox" @click="showSearchBox">
        <van-icon
          name="search"
          size="25"
          style="position: absolute; top: 0; font-weight: bold"
        />
      </div>

      <div class="haveDataBox" v-if="isHaveData">
        <div
          v-for="cItem in classifyData"
          :id="'gameClassify' + cItem.id"
          v-if="
            isShowClassify &&
            cItem.name != 'All' &&
            cItem.name != '全部' &&
            cItem.name != 'Todo'
          "
        >
          <Classify :classification="cItem"> </Classify>
          <div class="gameBox">
            <div
              class="gameItem"
              v-for="gItem in gameData"
              v-if="cItem.parentId == gItem.cid"
            >
              <Game :game="gItem"> </Game>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="noDataBox"></div>

      <van-action-sheet
        v-model="showSearchView"
        style="background-color: #1c2028"
      >
        <h3 style="color: white; margin-left: 20px">
          {{ $t("common.home.search") }}
        </h3>
        <div class="searchBoxTop">
          <!-- <input type="text" class="searchInput" name="" id="" placeholder="Enter game name"> -->
          <van-search
            v-model="searchWord"
            @input="searchHandle"
            class="searchInput"
            background="none"
            input-align="center"
            :placeholder="$t('common.home.enterGameName')"
          />
          <div class="userAction" @click="toUserActionPage('history')">
            <van-icon color="white" name="browsing-history" size="24" />
          </div>
          <div class="userAction" @click="toUserActionPage('favorite')">
            <van-icon color="white" name="like" size="24" />
          </div>
        </div>
        <p style="text-align: center; font-size: 13px; color: gray">
          {{ $t("common.home.searchLimit") }}
        </p>
        <div class="searchWordHistorybox">
          <!-- <div class="searchWordHistoryboxItem" v-for="item in 10"> -->
          <div
            class="searchWordHistoryboxItem"
            v-for="item in searchWoldHistory"
            @click="(searchWord = item), findGameByWord()"
          >
            {{ item }}
            <span @click="deleteSearchWord(item)">
              <van-icon name="cross"
            /></span>
          </div>

          <div class="clearSearchWordHistory" @click="clearHistorySearchWord">
            <van-icon color="#516382" name="delete-o" size="16" />
          </div>
        </div>

        <div class="recentBox" v-if="searchGameData.length > 0">
          <van-icon
            name="checked"
            size="22"
            color="#ccc"
            style="vertical-align: middle"
          />
          {{ $t("common.home.result") }}
        </div>
        <div class="gameBox" v-if="searchGameData.length > 0">
          <div class="gameItem" v-for="gItem in searchGameData" @click="">
            <Game :game="gItem"> </Game>
          </div>
        </div>

        <div
          class="recentBox"
          v-if="
            !(
              this.historyGameData == null ||
              JSON.stringify(this.historyGameData) == '[]'
            )
          "
        >
          <van-icon
            name="clock"
            size="22"
            color="#ccc"
            style="vertical-align: middle"
          />
          {{ $t("common.home.history") }}
        </div>

        <div
          class="gameBox"
          v-if="
            !(
              this.historyGameData == null ||
              JSON.stringify(this.historyGameData) == '[]'
            )
          "
        >
          <div class="gameItem" v-for="gItem in historyGameData" @click="">
            <Game :game="gItem"> </Game>
          </div>
        </div>

        <div style="width: 100%; height: 20px"></div>
      </van-action-sheet>

      <div class="gameBoxByClassificationBox" v-if="!isShowClassify">
        <div class="gameByClassificationItem" v-for="item in gameData">
          <Game :game="item"> </Game>
        </div>
      </div>
    </div>

    <div class="homeplaceorder"></div>
  </div>
</template>

<script>
import Slideshow from "./Slideshow.vue";
import Classify from "./Classify.vue";
import Game from "./Game.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      show: false,
      classifyData: [],
      gameData: [],
      gameByClassification: [],
      historyGameData: [],
      searchGameData: [],
      searchWoldHistory: [],
      isShowClassify: true,
      pageHeight: "",
      pageWidth: "",
      backColor: "#15161c",
      showSearchView: false,
      isHaveData: true,
      searchWord: "",
    };
  },
  methods: {
    clearHistorySearchWord() {
      localStorage.removeItem("7topgame.com-search-word-arrs");
      this.searchWoldHistory = [];
    },
    deleteSearchWord(e) {
      for (let i = 0; i < this.searchWoldHistory.length; i++) {
        const element = this.searchWoldHistory[i];
        if (element == e) {
          this.searchWoldHistory.splice(i, 1);
          break;
        }
      }
      localStorage.setItem(
        "7topgame.com-search-word-arrs",
        JSON.stringify(this.searchWoldHistory)
      );
    },

    searchHandle() {
      if (this.searchWord.length >= 3) {
        this.findGameByWord();
      }
    },
    findGameByWord() {
      if (this.searchWord.length < 3) {
        return;
      }

      Toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });

      setTimeout(() => {
        this.$axios
          .get(
            this.$Host +
              "/client/game/findByBlurGameName?lang=" +
              localStorage.getItem("7topgame.com-user-lang") +
              "&gameName=" +
              this.searchWord
          )
          .then((res) => {
            if (res.data.code == 10041) {
              this.searchGameData = res.data.data;
            }
            Toast.clear();

            let arr = localStorage.getItem("7topgame.com-search-word-arrs");
            if (arr == null || arr.length <= 0) {
              let newArr = [];
              newArr.push(this.searchWord);
              localStorage.setItem(
                "7topgame.com-search-word-arrs",
                JSON.stringify(newArr)
              );
            } else {
              let newArr = JSON.parse(arr);
              newArr.push(this.searchWord);
              localStorage.setItem(
                "7topgame.com-search-word-arrs",
                JSON.stringify(newArr)
              );
            }
            for (let i = 0; i < this.searchWoldHistory.length; i++) {
              const element = this.searchWoldHistory[i];
              if (element == this.searchWord) {
                return;
              }
            }
            this.searchWoldHistory.push(this.searchWord);
          })
          .catch((err) => {
            Toast(err);
          });
      }, 1500);
    },

    showSearchBox() {
      this.showSearchView = true;
      this.historyGameData = JSON.parse(
        localStorage.getItem("7topgame.com-user-history")
      );
      this.getSearchWordHistory();
    },

    getSearchWordHistory() {
      let searchWord = JSON.parse(
        localStorage.getItem("7topgame.com-search-word-arrs")
      );
      if (searchWord == null || searchWord.length <= 0) {
        return;
      }
      this.searchWoldHistory = Array.from(new Set(searchWord));
      // console.log(typeof (this.searchWoldHistory), this.searchWoldHistory);
      localStorage.setItem(
        "7topgame.com-search-word-arrs",
        JSON.stringify(this.searchWoldHistory)
      );
    },

    toUserActionPage(e) {
      this.$store.commit("userActionchange", e);
      this.$router.push("/user/action");
    },

    clickShowClassify(e) {
      if (e.name == "All" || e.name == "全部" || e.name == "Todo") {
        this.findAll();
        this.initializeClassify();
      }
    },

    findGameByPageAndClassify(e, index) {
      let eles = document.querySelectorAll(".classifyItem");
      Toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });
      eles[index].className = "classifyItem classifyItemSelected";
      for (let i = 0; i < eles.length; i++) {
        const element = eles.length[i];
        if (i != index) {
          eles[i].className = "classifyItem";
        }
      }

      this.isShowClassify = true;
      this.isHaveData = false;

      if (e.name == "All" || e.name == "全部" || e.name == "Todo") {
        this.findAll();
        // location.reload();
        return;
      }
      let cond = {
        begin: 1,
        size: 20,
        lang: localStorage.getItem("7topgame.com-user-lang"),
        classifyName: e.name,
      };
      this.$axios
        .post(this.$Host + "/client/game/findByPage", cond)
        .then((res) => {
          if (res.data.code == 10041) {
            this.gameData = res.data.data.data;
            this.isShowClassify = false;
            setTimeout(() => {
              this.isHaveData = true;
              Toast.clear();
            }, 1000);
            return;
          }
          Toast(res.data.msg);
        })
        .catch((err) => {
          Toast(err);
        });
    },

    scrollToItem(e) {
      let ele = document.querySelector("#" + e);

      // let targetLocation = ele.offsetTop
      // let duration = 500
      // let step = Math.floor(targetLocation / duration * 15)
      // let interVal = setInterval(() => {
      //     let scrollTop = targetLocation.scrollTop || document.body.scrollTop
      //     if (scrollTop <= targetLocation || scrollTop <= 0) {
      //         clearInterval(interVal)
      //         return
      //     }
      //     document.documentElement.scrollTop = document.body.scrollTop = scrollTop - step
      // }, 15);

      ele.scrollIntoView({ behavior: "smooth" });
    },
    findGameByPage() {
      let cond = {
        begin: 1,
        size: 10,
        lang:
          localStorage.getItem("7topgame.com-user-lang") == null
            ? "en"
            : localStorage.getItem("7topgame.com-user-lang"),
      };
      this.$axios
        .post(this.$Host + "/client/game/findByPage", cond)
        .then((res) => {
          if (res.data.code == 10041) {
            this.$store.commit("addHistoryRecords", res.data.data.data);
            this.gameData = res.data.data.data;
            return;
          }
          Toast(res.data.msg);
        })
        .catch((err) => {
          Toast(err);
        });
    },
    findAll() {
      let gameList = localStorage.getItem("7topgame.com-game-list");
      let lCode = localStorage.getItem("7topgame.com-user-lang");
      if (lCode == null) {
        lCode = "en";
        localStorage.setItem("7topgame.com-user-lang", "en");
      }
      if (gameList == null) {
        this.$axios
          .get(this.$Host + "/client/game/findAll?lang=" + lCode)
          .then((res) => {
            if (res.data.code == 10041) {
              localStorage.setItem(
                "7topgame.com-game-list",
                JSON.stringify(res.data.data)
              );
              this.gameData = res.data.data;
              setTimeout(() => {
                this.isHaveData = true;
                Toast.clear();
              }, 1000);
              return;
            }
            Toast(res.data.msg);
          })
          .catch((err) => {
            Toast(err);
          });
        return;
      }

      if (JSON.parse(gameList).length > 0) {
        this.gameData = JSON.parse(gameList);
        setTimeout(() => {
          this.isHaveData = true;
          Toast.clear();
        }, 1000);
        return;
      }
    },
    initializeClassify() {
      let lCode = localStorage.getItem("7topgame.com-user-lang");
      if (lCode == null) {
        lCode = "en";
        localStorage.setItem("7topgame.com-user-lang", "en");
      }
      
      // let localClassification = JSON.parse(
      //   localStorage.getItem("7topgame.com-classify")
      // );
      // if (localClassification.length > 0) {
      //   this.classifyData = localClassification;
      //   return;
      // }

      this.$axios
        .get(this.$Host + "/common/gameClassify/findByLanguage?l=" + lCode)
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 10041) {
            this.classifyData = res.data.data;
            localStorage.setItem(
              "7topgame.com-classify",
              JSON.stringify(res.data.data)
            );
            return;
          }
          Toast(res.data.msg);
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
  components: {
    Slideshow,
    Classify,
    Game,
  },
  mounted() {
    this.initializeClassify();
    this.findAll();
    let height = window.innerHeight;
    let width = window.innerWidth;
    this.pageHeight = height - 2 + "px";
    this.pageWidth = width - 2 + "px";
  },
};
</script>

<style>
.classifyNavBox {
  display: inline-block;
  margin: 0 auto;
  margin-top: 15px;
  margin-left: 8px;
  max-width: 87%;
  height: 30px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 6px;
}

.classifyNavBox::-webkit-scrollbar {
  display: none;
}

.classifyItem {
  display: inline-block;
  position: relative;
  margin-right: 4px;
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  background-color: #441e71;
  color: white;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  /* border-bottom: 2px solid #7d37cf; */
}

.searchBox {
  display: inline-block;
  position: relative;
  margin: 0 0 0 5px;
  width: 32px;
  height: 30px;
  color: #9239fb;
}

.searchBoxTop {
  margin: 0 auto;
  width: 95%;
  height: 40px;
  /* overflow: hidden; */
  /* background-color: aqua; */
}

.userAction {
  /* display: inline-block; */
  float: right;
  width: 40px;
  height: 80%;
  background-color: #17191f;
  text-align: center;
  padding-top: 8px;
  margin-right: 8px;
  border-radius: 6px;
}

.searchInput {
  width: 70%;
  height: 38px;
  border: none;
  margin-left: 5px;
  border-radius: 5px;
  overflow: hidden;
  outline: none;
  vertical-align: middle;
  font-size: 14px;
  text-align: center;
  background-color: black;
}

.searchInput .van-search__content {
  background-color: black;
  height: 40px;
}

.searchBoxTop .van-search {
  display: inline-block;
  padding: 0;
  height: 40px;
}

.searchBoxTop .van-field__control {
  margin: 4px;
  margin-top: 4px;
  color: white;
}

.van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  display: block;
  font-size: 18px;
  line-height: inherit;
  margin-top: 5px;
}

.searchBox .van-field__control--center {
  font-size: 14px;
  color: white;
}

.iconPosition {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
}

.iconPosition img {
  /* vertical-align: middle; */
  width: 20px;
  height: 20px;
}

.classifyItem a {
  display: inline-block;
  background-color: #441e71;
  color: white;
  font-size: 12px;
}

/* .classifyNavBox :first-child {
  font-size: 15px;
} */

.classifyItemSelected {
  font-size: 15px;
}

.gameBox {
  margin: 0 auto;
  width: 97%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 8px;
}

.gameBox::-webkit-scrollbar {
  display: none;
}

.gameItem {
  display: inline-block;
  margin: 3px 0 3px 6px;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 99.5%;
  height: 50px;
  background-color: #1b2746;
}

.homeplaceorder {
  margin-bottom: 50px;
}

.gameBoxByClassificationBox {
  margin: 0 auto;
  width: 100%;
  min-height: 820px;
  border-radius: 8px;
  /* background-color: #080d1b; */
}

.gameByClassificationItem {
  display: inline-block;
  margin: 3px 0 3px 6px;
  border-radius: 7px;
  overflow: hidden;
}

.searchWordHistorybox {
  position: relative;
  padding-top: 1px;
  margin: 0 auto;
  width: 95%;
  height: 70px;
  overflow: hidden;
}

.searchWordHistoryboxItem {
  float: left;
  margin: 5px;
  min-width: 15%;
  max-width: 25%;
  height: 25px;
  border-radius: 9px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  color: #ccc;
  background-color: #171a1f;
}

.clearSearchWordHistory {
  position: absolute;
  right: 5px;
  /* top: 7px; */
}

.recentBox {
  margin: 10px auto;
  width: 90%;
  height: 25px;
  line-height: 25px;
  color: white;
}

.recentGameBox {
  margin: 0 auto;
  width: 95%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.recentGameItemBox {
  float: left;
  margin: 0 auto;
  margin-left: 10px;
}

.noDataBox {
  margin: 0 auto;
  width: 100%;
  min-height: 800px;
  background: none;
}
</style>
