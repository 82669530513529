<template>
    <div :style="{
        width: pageWidth,
        height: pageHeight,
        backgroundColor: backColor,
    }">

        <Navi></Navi>

        <div class="topOperateNavBox">
            <div class="favorite" @click="userActionchange('favorite')">
                <van-icon name="like" size="22" style="vertical-align: middle;" />
                {{ $t('common.userAction.favorite') }}
            </div>
            <div class="history" @click="userActionchange('history')">
                <van-icon name="browsing-history" size="22" style="vertical-align: middle;" />
                {{ $t('common.userAction.history') }}
            </div>
        </div>

        <div style="background-color: #15161c;">
            <div class="none" v-if="isShowNullTip">
                <img :src="$Host + '/images/historynull.png'" alt="">
            </div>
            <div class="favoriteBox" v-if="isChooseFavorite">
                <div class="gameItem" v-for="item in favoriteGameData">
                    <Game :game="item">
                    </Game>
                </div>
            </div>
            <div class="historyBox" v-else v-for="item in historyGameData" @click="toGames(item)">
                <div class="historyGameImg">
                    <img :src="item.image" alt="">
                </div>
                <div class="historyGameInfo">
                    <div class="historyGameName">
                        {{ item.gameName }}
                    </div>
                    <div class="description">
                        {{ $t("common.userAction.start") }}
                    </div>
                    <div class="openNums">
                        {{ item.openNum }} {{ $t("common.userAction.played") }}
                    </div>
                </div>
            </div>
        </div>

        <div class="placeorder"></div>

        <footer class="bottom">
            <Bottom></Bottom>
        </footer>
    </div>
</template>

<script>
import Bottom from '../components/Bottom.vue';
import Navi from './Navi.vue'
import Game from '../components/Game.vue';
export default {
    data() {
        return {
            pageHeight: "",
            pageWidth: "",
            backColor: "#15161c",
            isChooseFavorite: false,
            favoriteGameData: [],
            historyGameData: [],
            isShowNullTip: false
        }
    },
    methods: {
        isShowNullTipControll() {
            if (!this.isChooseFavorite) {
                if (this.historyGameData == null || (this.historyGameData.length <= 0)) {
                    this.isShowNullTip = true
                } else {
                    this.isShowNullTip = false
                }
            } else {
                if (this.favoriteGameData == null || (this.favoriteGameData.length <= 0)) {
                    this.isShowNullTip = true
                } else {
                    this.isShowNullTip = false
                }
            }
        },
        toGames(item) {
            this.$axios
                .get(this.$Host + "/client/game/openGame?id=" + item.id)
                .then((res) => {
                    location.href = item.gameUrl
                })
                .catch((err) => {
                });
        },
        userActionchange(val) {
            this.$store.commit("userActionchange", val)
            let his = document.querySelector(".history")
            let fav = document.querySelector(".favorite")

            if (this.$store.state.userAction == "history") {
                his.className = "history selected"
                fav.className = "favorite"
                this.isChooseFavorite = false
                this.isShowNullTipControll()
            } else {

                fav.className = "favorite selected"
                his.className = "history"
                this.isChooseFavorite = true
                this.isShowNullTipControll()
            }
        },
        filterFavoriteGame() {
            let gameData = JSON.parse(localStorage.getItem('7topgame.com-game-list'))
            let userCollect = JSON.parse(localStorage.getItem('7topgame.com-user-collect'))
            let favoriteGames = []
            if ((userCollect == null) || userCollect.length <= 0) {
                return
            }
            for (let i = 0; i < gameData.length; i++) {
                for (let j = 0; j < userCollect.length; j++) {
                    const collect = userCollect[j];
                    const game = gameData[i];
                    if (game.id == collect.gameId) {
                        favoriteGames.push(game)
                    }
                }
            }
            this.favoriteGameData = favoriteGames
        }
    },
    components: {
        Bottom,
        Navi,
        Game
    },
    mounted() {
        // this.findGameByPage()
        let userAction = this.$store.state.userAction
        this.userActionchange(userAction)
        this.historyGameData = JSON.parse(localStorage.getItem('7topgame.com-user-history'))

        this.filterFavoriteGame()

        this.isShowNullTipControll()

        let height = window.innerHeight;
        let width = window.innerWidth;
        this.pageHeight = height - 2 + "px";
        this.pageWidth = width - 2 + "px";
    }
}
</script>

<style>
/* .bottom {
    position: fixed;
    bottom: 0;
    width: 99.5%;
    height: 40px;
    background-color: #1b2746;
} */

.topOperateNavBox {
    display: flex;
    margin: 10px auto;
    width: 95%;
    height: 40px;
    border-radius: 10px;
    background-color: #1c2028;
}

.topOperateNavBox div {
    flex: 1;
    text-align: center;
    line-height: 40px;
    color: #555;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
}

.topOperateNavBox .selected {
    background-color: #3d1e6c;
    color: white;
    border-bottom: 2px solid #6313db;
}

.favoriteBox {
    position: relative;
    margin: 0 auto;
    margin-top: 10px;
    width: 95%;
    border-radius: 4px;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: hidden;
    /* white-space: nowrap; */
    border-radius: 8px;
}

.gameItem {
    display: inline-block;
    margin: 3px 0 3px 6px;
}

.historyBox {
    margin: 10px auto;
    display: flex;
    width: 96%;
    height: 20%;
    border-radius: 8px;
    background-color: rgb(44, 41, 41);
}

.historyBox {
    flex: 1;
    /* width: 30%;
    height: 100%; */
    margin: 0 auto;
    margin-top: 12px;
}

.historyBox .historyGameImg {
    flex: .6;
    margin: 0 5px;
    border-radius: 5px;
    overflow: hidden;
}

.historyBox .historyGameInfo {
    flex: 1.3;
    margin: 0 5px;
}

.historyGameImg img {
    position: relative;
    top: 50%;
    left: 50%;
    max-width: 180%;
    max-height: 100%;
    transform: translate(-50%, -50%);
}

.historyGameInfo .historyGameName {
    margin: 10px;
    font-size: 18px;
    text-indent: 1rem;
    font-weight: bold;
    color: #ccc;
}

.historyGameInfo .description {
    margin: 10px;
    font-size: 14px;
    text-indent: 1rem;
    color: white;
}

.historyGameInfo .openNums {
    margin: 10px;
    font-size: 14px;
    text-indent: 1rem;
    color: white;
}

.placeorder {
    width: 100%;
    height: 60px;
    background-color: #15161c;
}

.none {
    margin: 0px auto;
    margin-top: 50px;
    width: 200px;
    height: 200px;
    /* border-radius: 100px; */
    overflow: hidden;
}

.none img {
    width: 100%;
    height: 100%;
}
</style>
