<template>
  <div class="mainBox" :style="{
    // height: pageHeight,
    width: pageWidth,
    minHeight: '700px',
    backgroundColor: backColor,
    // backgroundImage: isMainHome ? gradient : ''
  }">
    <!-- <div v-if="isMainHome" class="box1"></div>
    <div v-if="isMainHome" class="box2"></div>
    <div v-if="isMainHome" class="box3"></div> -->

    <Navi ref="Navi"></Navi>

    <main>
      <router-view></router-view>
    </main>

    <footer class="bottom">
      <Bottom></Bottom>
    </footer>
  </div>
</template>

<script>
import Navi from "../views/Navi";
import Bottom from "../components/Bottom.vue";
import request from "../request/request";
export default {
  data() {
    return {
      accessId: "",
      orderedDetail: {},
      seeOrderedDialog: false,
      isMainHome: false,
      pageHeight: "",
      pageWidth: "",
      backColor: "#15161c",
      // gradient: 'linear-gradient(to bottom left, #a21b80, #080d1b 60%)'
    };
  },

  // 监听器
  watch: {
    $route: function (to, from) {
      this.isShowGradient();
    },
  },

  methods: {
    webSocketInit() {
      let userInfo = localStorage.getItem("7topgame.com-user-info");
      if (JSON.parse(userInfo) == '{}' || userInfo == null) {
        return;
      }
      let uid = JSON.parse(userInfo).id;
      const socket = new WebSocket(request.WEBSOCKET + uid);

      socket.onopen = function (event) {
        console.log("WebSocket 连接已打开");
      };

      socket.onmessage = function (event) {
        console.log("收到消息:", result);
        if (result.code == 20001) {
        }
        if (result.code == 20000) {
        }
      };

      socket.onclose = function (event) {
        console.log("WebSocket 连接已关闭");
      };

      socket.onerror = function (error) {
        console.error("WebSocket 出现错误:", error);
      };
    },
    isShowGradient() {
      if (this.$route.path.includes("/profile")) {
        this.isMainHome = false;
      }
      if (this.$route.path.includes("/home")) {
        this.isMainHome = true;
      }
    },

    // loginWithGoogle() {
    //   const provider = new firebase.auth.GoogleAuthProvider();
    //   firebase.auth().signInWithPopup(provider).then(result => {
    //     console.log(result, "app");

    //   }).catch(error => {
    //     console.log(error, "app");

    //   });
    // }
  },
  mounted() {
    let height = window.innerHeight;
    let width = window.innerWidth;
    this.pageHeight = height + "px";
    this.pageWidth = width + "px";
    console.log("当前浏览器页面的高度是：" + this.pageHeight + "像素");
    console.log("当前浏览器页面的宽度是：" + this.pageWidth + "像素");
    this.webSocketInit();
    setInterval(() => {
      this.webSocketInit();
    }, 50000);
    this.isShowGradient();
  },

  components: {
    Navi,
    Bottom,
  },
};
</script>

<style>
.el-header {
  padding: 0;
  margin: 0;
}

.mainPage {
  padding: 1px;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 99.5%;
  height: 50px;
  background-color: #1b2746;
}

.mainBox {
  position: relative;
  overflow: hidden;
  /* background-image: linear-gradient(to bottom left, #a21b80, #080d1b 60%); */
  /* background: radial-gradient(circle, #a21b80,#080d1b); */
  /* background-image: linear-gradient(to bottom, #3d6d2d , #2452d3 100%); */
}

/* .box1 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 20%;
  background-image: linear-gradient(to bottom left, #a21b80, #080d1b 60%);
  z-index: -1;
} */

/* .box2 {
  position: absolute;
  top: 30%;
  left: -80%;
  width: 180%;
  height: 40%;
  background: radial-gradient(circle, #741da1, #15161c 62%);
  z-index: -1;
}

.box3 {
  position: absolute;
  bottom: -15%;
  right: -80%;
  width: 180%;
  height: 40%;
  background: radial-gradient(circle, #083996, #15161c 50%);
  z-index: -1;
} */
</style>
